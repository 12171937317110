import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const MASTER_DATA_LAYOUT_ROUTE: Routes = [
    {
        path: RoutePath.weekly_diet_set,
        canActivate: [AuthGuard],
        data:{ pageId: ['weekly-diet-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/weekly-diet-set/weekly-diet-set.module').then((m) => m.WeeklyDietSetModule),
    },
    {
        path: RoutePath.daily_diet_set,
        canActivate: [AuthGuard],
        data:{ pageId: ['weekly-diet-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/daily-diet-set/daily-diet-set.module').then((m) => m.DailyDietSetModule),
    },
    {
        path: RoutePath.recipes_set,
        canActivate: [AuthGuard],
        data:{ pageId: ['recipes-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/recipes-set/recipes-set.module').then((m) => m.RecipesSetModule),
    },
    {
        path: RoutePath.weekly_exercise_sets,
        canActivate: [AuthGuard],
        data:{ pageId: ['weekly-exercise-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/exercise-master/weekly-exercise-set/weekly-exercise-set.module').then((m) => m.WeeklyExerciseSetModule),
    },
    {
        path: RoutePath.excercise_set,
        canActivate: [AuthGuard],
        data:{ pageId: ['exercises-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/exercise-master/exercise-set/exercise-set.module').then((m) => m.ExerciseSetModule),
    },
    {
        path: RoutePath.weekly_yoga_sets,
        canActivate: [AuthGuard],
        data:{ pageId: ['weekly-yoga-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/weekly-yoga-sets/weekly-yoga-sets.module').then((m) => m.WeeklyYogaSetsModule),
    },
    {
        path: RoutePath.yoga_sets,
        canActivate: [AuthGuard],
        data:{ pageId: ['yoga-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/yoga-sets/yoga-sets.module').then((m) => m.YogaSetsModule),
    },
    {
        path: RoutePath.weekly_mind_activity,
        canActivate: [AuthGuard],
        data:{ pageId: ['weekly-mind-activity'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/weekly-mind-activity/weekly-mind-activity.module').then((m) => m.WeeklyMindActivityModule),
    },
    {
        path: RoutePath.mind_activity,
        canActivate: [AuthGuard],
        data:{ pageId: ['mind-activity'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/mind-activity/mind-activity.module').then((m) => m.MindActivityModule),
    },
    {
        path: RoutePath.product_category,
        canActivate: [AuthGuard],
        data:{ pageId: ['product-category'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/product-category/product-category.module').then((m) => m.ProductCategoryModule),
    },
    {
        path: RoutePath.promise,
        canActivate: [AuthGuard],
        data:{ pageId: ['promise'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/promise/promise.module').then((m) => m.PromiseModule),
    },
    {
        path: RoutePath.manage_products,
        canActivate: [AuthGuard],
        data:{ pageId: ['manage-products'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/manage-products/manage-products.module').then((m) => m.ManageProductsModule),
    },
    {
        path: RoutePath.product_bundle,
        canActivate: [AuthGuard],
        data:{ pageId: ['product-bundle'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/manage-product-bundle/manage-product-bundle.module').then((m) => m.ManageProductBundleModule),
    },
    {
        path: RoutePath.discount,
        canActivate: [AuthGuard],
        data:{ pageId: ['discount'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/manage-discount/manage-discount.module').then((m) => m.ManageDiscountModule),
    },
    {
        path: RoutePath.medical_condition,
        canActivate: [AuthGuard],
        data:{ pageId: ['medical-condition'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/medical-condition/medical-condition.module').then((m) => m.MedicalConditionModule),
    },
    {
        path: RoutePath.guidelines_set,
        canActivate: [AuthGuard],
        data:{ pageId: ['guidelines-sets'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/master-data/guidelines-master/guidelines-master.module').then((m) => m.GuidelinesMasterModule),
    },
]
