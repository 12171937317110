export const pagePaths = {

    // Default
    Empty: '',
    redirect: '/',
    full: 'full',

    // Auth
    auth: 'auth',
    login: 'login',
    verfication: 'verfication',

    // Dashboard
    dashboard: 'dashboard',
    my_dashboard: 'my-dashboard',
    today_task: 'today-task',
    weight_stuck: 'weight-stuck',
    sales_dashboard: 'sales-dashboard',
    calls_dashboard: 'calls-dashboard',
    opportunities_dashboard: 'opportunities-dashboard',
    CSAT_dashboard: 'CSAT-dashboard',

    // Sales & Revenue
    sales_revenue: 'sales-revenue',
    customers: 'customers',
    add_family: 'add-family',
    create_plan: 'create-plan',
    create_order: 'create-order',
    update_order: 'update-order',
    checkout_plan: 'checkout-plan',
    place_order: 'place-order',
    create_payment:'create-payment',
    cart: 'cart',
    orders: 'orders',
    payments: 'payments',
    offline_payments:'offline-payments',
    store: 'store',
    customer_view: 'customer-view',
    calculator: 'calculator',
    approvals: 'approvals',

    // Manage Users
    manage_users: 'manage-users',
    roster: 'roster',
    bulk_client_shifting: 'bulk-client-shifting',
    manage_members: 'manage-members',
    paymentLink: 'payment-links',
    reCounselling: 're-counselling',
    referral: 'referral',
    chats: 'chats',
    calls_logs: 'calls-logs',
    wellness_coaches: 'wellness-coaches',
    wellness: 'wellness',

    // Manage Members
    profile: 'profile',
    progress: 'progress',
    timelines: 'timelines',
    subscription_payment: 'subscription-payment',
    subscription: 'subscription',
    suggest_diet: 'suggest-diet',
    suggest_exercise: 'suggest-exercise',
    suggest_yoga: 'suggest-yoga',
    suggest_mind_activity: 'suggest-mind-activity',
    followups: 'follow-ups',
    start_followup: 'start-followup',
    counselling: 'counselling',
    start_counselling: 'start-counselling',
    mind_counselling: 'mind-counselling',
    book_slots: 'book-slots',
    CSAT: 'CSAT',
    medical_reports: 'medical-reports',
    guidelines: 'guidelines',
    assessments: 'assessments',
    customer_calls_logs: 'customer-calls-logs',
    shuffle_counselling:'shuffle-counselling',
    reschedule_counselling: 'reschedule-counselling',
    specific_dietitian: 'specific-dietitian',
    skill_set : 'skill-set',
    rm_notes : 'rm-notes',
    doctor_notes : 'doctor-notes',
    
    // Me
    me: 'me',
    roster_management: 'roster-management',
    roster_slots: 'roster-slots',
    unavailability: 'unavailability',
    my_profile: 'my-profile',
    view_profile: 'view-profile',

    // Organization
    organization: 'organization',
    manage_employees: 'manage-employees',
    system_users: 'system-users',
    add_employee: 'add-employee',
    update_employee: 'update-employee',
    view_employee: 'view-employee',
    manage_roles: 'manage-roles',
    add_role: 'add-role',
    update_role: 'update-role',
    view_role: 'view-role',
    employee_configuration:'employee-configuration',
    roaster_configuration:'roaster-configuration',

    // Master Data
    master_data: 'master-data',
    promise: 'promise',
    weekly_diet_set: 'weekly-diet-sets',
    view_weekly_diet: 'view-weekly-diet',
    add_weekly_diet: 'add-weekly-diet',
    edit_weekly_diet: 'edit-weekly-diet',
    daily_diet_set: 'daily-diet-sets',
    add_daily_diet: 'add-daily-diet',
    edit_daily_diet: 'edit-daily-diet',
    view_daily_diet: 'view-daily-diet',
    recipes_set: 'recipes-sets',
    discount: 'discount',
    add_discount: 'add-discount',
    view_discount: 'view-discount',
    edit_discount: 'edit-discount',
    medical_condition: 'medical-condition',
    guidelines_set: 'guidelines-sets',
    add_recipe: 'add-recipe',
    edit_recipe: 'edit-recipe',
    weekly_exercise_sets: 'weekly-exercise-sets',
    add_weekly_exercise: 'add-weekly-exercise',
    edit_weekly_exercise: 'edit-weekly-exercise',
    view_weekly_exercise: 'view-weekly-exercise',
    mind_activity: 'mind-activity',
    weekly_mind_activity: 'weekly-mind-activity',
    add_weekly_activity: 'add-weekly-activity',
    edit_weekly_activity: 'edit-weekly-activity',
    view_weekly_activity: 'view-weekly-activity',
    weekly_yoga_sets: 'weekly-yoga-sets',
    add_weekly_yoga: 'add-weekly-yoga',
    edit_weekly_yoga: 'edit-weekly-yoga',
    view_weekly_yoga: 'view-weekly-yoga',
    excercise_set: 'exercises-sets',
    yoga_sets: 'yoga-sets',
    manage_products: 'manage-products',
    product_category: 'product-category',
    add_product: 'add-product',
    edit_product: 'edit-product',
    product_bundle: 'product-bundle',
    add_bundle: 'add-bundle',
    edit_bundle: 'edit-bundle',
    manage_discount:'manage_discount',

    // Contents
    contents: 'contents',
    transformations: 'transformations',
    banners: 'banners',
    stories: 'stories',
    posts: 'posts',

    // Others
    star: '**',
    notes: 'notes',
    pageNot_found: 'page-not-found',
    unauthorised: 'unauthorised',
    Unauthorized: 'Unauthorized',
    
}
export const RoutePath = {
    ...pagePaths,
}
